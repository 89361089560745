import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, Stack } from "@mui/material";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { VerifyOtpDialog } from "@src/appV2/Auth/LoginSignUp/Dialogs";
import { AuthMode } from "@src/appV2/Auth/LoginSignUp/libs";
import { useStepUpAuthContext } from "@src/appV2/Auth/LoginSignUp/StepUpAuth/context";
import { StepUpAuthConfirmationText } from "@src/appV2/Auth/LoginSignUp/StepUpAuth/StepUpAuthConfirmationText";
import { StepUpAuthRequestOtpButton } from "@src/appV2/Auth/LoginSignUp/StepUpAuth/StepUpAuthRequestOtpButton";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { DeleteAccountConfirmationDialog } from "./DeleteAccountConfirmationDialog";
import { DeleteAccountDialog } from "./DeleteAccountDialog";

export function DeleteAccountPage() {
  const {
    data: agentProfile,
    isLoading: agentProfileIsLoading,
    isSuccess: agentProfileIsSuccess,
  } = useAgentProfile();
  const phoneNumber = agentProfileIsSuccess ? agentProfile?.phone : "";

  const verifyOtpModalState = useModalState();
  const deleteAccountModalState = useModalState();
  const confirmDeleteAccountModalState = useModalState();

  const { isRequestingOtp } = useStepUpAuthContext();

  const history = useHistory();

  return (
    <>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Delete Account"
            leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.PROFILE} />}
          />
        }
        footer={
          <Stack gap={2}>
            <StepUpAuthRequestOtpButton
              isLoading={agentProfileIsLoading}
              phoneNumber={phoneNumber}
              eventName={APP_V2_USER_EVENTS.REQUEST_ACCOUNT_DELETION_SEND_OTP}
              onSuccess={() => {
                verifyOtpModalState.openModal();
              }}
            />
            <Button
              disabled={isRequestingOtp}
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.REQUEST_ACCOUNT_DELETION_CANCEL_AT_OTP);
                history.push(DeprecatedGlobalAppV1Paths.PROFILE);
              }}
            >
              Cancel
            </Button>
          </Stack>
        }
      >
        <StepUpAuthConfirmationText phoneNumber={phoneNumber} isLoading={agentProfileIsLoading} />
      </PageWithHeader>

      {isDefined(phoneNumber) && (
        <VerifyOtpDialog
          modalState={verifyOtpModalState}
          phoneNumber={phoneNumber}
          authMode={AuthMode.AD_HOC}
          onSuccess={async () => {
            verifyOtpModalState.closeModal();
            deleteAccountModalState.openModal();
          }}
        />
      )}

      <DeleteAccountDialog
        modalState={deleteAccountModalState}
        onSuccess={() => {
          deleteAccountModalState.closeModal();
          confirmDeleteAccountModalState.openModal();
        }}
        onFailure={() => {
          verifyOtpModalState.closeModal();
          deleteAccountModalState.closeModal();
          confirmDeleteAccountModalState.closeModal();
        }}
      />

      <DeleteAccountConfirmationDialog modalState={confirmDeleteAccountModalState} />
    </>
  );
}
